/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
markdown table {
  margin: auto !important;
}

.text-center {
  text-align: center !important;
}

.mr-2 {
  margin-right: 0.5em;
}

.ant-page-header {
  padding: 0;
}

.code-block pre {
  max-height: 400px;
}

.button-groups {
  padding: 16px 0;
}
.button-groups button {
  margin-right: 16px;
}

.demo-chart {
  height: 20rem;
  width: 100%;
}

.demo-chart-600 {
  height: 600px;
}